import { useRouter } from 'next/router'
import { useMemo } from 'react'

export function formatPrice({
  amount,
  currencyCode,
  locale,
}: {
  amount: number
  currencyCode: string
  locale: string | undefined
}) {
  const formatCurrency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  })
  return formatCurrency.format(amount)
}

export default function usePrice(
  data?: {
    amount: number | undefined
    currencyCode: string
    suffix?: string
  } | null
) {
  const { amount, currencyCode } = data ?? {}
  const { locale } = useRouter()
  const priceSuffix = locale?.endsWith('-eu') ? data?.suffix || '' : ''
  const value = useMemo(() => {
    if (typeof amount !== 'number' || !currencyCode) return ''
    return `${formatPrice({ amount, currencyCode, locale })} ${priceSuffix}`
  }, [amount, currencyCode, locale, priceSuffix])

  return typeof value === 'string' ? { price: value } : value
}
