import commerceConfig from '@commerce/config'
import processLocale from '@lib/locale'

const getCurrencyCountryCode = (locale: string | undefined) => {
  const {
    config: { context },
  } = commerceConfig
  const currentLocale = processLocale(locale)
  const currentContext = context?.[currentLocale]

  return currentContext?.defaultCurrencyCountryCode
}

export default getCurrencyCountryCode
