import commerceConfig from '@commerce/config'
import processLocale from '@lib/locale'

const getLanguage = (locale: string | undefined) => {
  const {
    config: { context },
  } = commerceConfig
  const currentLocale = processLocale(locale)
  const currentContext = context?.[currentLocale] || 'en-au'

  return currentContext?.language
}

export default getLanguage
