import { Product } from '@commerce/types'
import { ContextualizeProduct } from '@commerce/types/product'

export const metafieldListForGraphQl = `[${[
  '{ namespace: "my_fields", key: "small_set_variant" }',
  '{ namespace: "my_fields", key: "small_set_name" }',
  '{ namespace: "my_fields", key: "small_set_description" }',
  '{ namespace: "my_fields", key: "large_set_variant" }',
  '{ namespace: "my_fields", key: "large_set_name" }',
  '{ namespace: "my_fields", key: "large_set_description" }',
  '{ namespace: "my_fields", key: "short_description" }',
  '{ namespace: "my_fields", key: "similar_products" }',
  '{ namespace: "my_fields", key: "similar_colours" }',
  '{ namespace: "my_fields", key: "landing_page_link" }',
  '{ namespace: "my_fields", key: "landing_page_display_price" }',
  '{ namespace: "my_fields", key: "limewash_paint" }',
  '{ namespace: "my_fields", key: "garden_pot_paint" }',
  '{ namespace: "my_fields", key: "sample_pot" }',
  '{ namespace: "my_fields", key: "colour_card" }',
  '{ namespace: "my_fields", key: "paint_15_litre" }',
].join(',')}]`

export const variantMetafieldsListForGraphQl = `[${[
  '{ namespace: "my_fields", key:"recipe"}',
].join(',')}]`

// replace product details with Contextualize product Details
export const rPDWCPD = (
  product: Product.Product,
  cProduct: ContextualizeProduct
) => {
  if (product?.originalPrice) return product

  product.originalPrice = product.price
  product.price = cProduct.price
  product.variants = product.variants.map((v) => {
    const cv = cProduct.variants.find((cv) => cv.id === v.id)
    v.originalPrice = v.price
    v.price = cv!.price
    return v
  })
  return product
}
